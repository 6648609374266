import { translate } from '../../i18n'

import mbalaz from '../../assets/images/keyPeople/mbalaz.webp'
import mkkolibjarova from '../../assets/images/keyPeople/mkkolibjarova.webp'
import emichalik from '../../assets/images/keyPeople/emichalik.webp'
import mpalicka from '../../assets/images/keyPeople/mpalicka.webp'
import rnaroda from '../../assets/images/keyPeople/rnaroda.webp'
import rkovac from '../../assets/images/keyPeople/rkovac.webp'
import rslamka from '../../assets/images/keyPeople/rslamka.webp'
import lschmoger from '../../assets/images/keyPeople/lschmoger.webp'
import enegruta from '../../assets/images/keyPeople/enegruta.webp'

export interface IKeyPeople {
  photo: string
  subtitle: string
  title: string
}

export const generateKeyPeople = (): IKeyPeople[] => [
  {
    ...translate.i18n.KEY_PEOPLE.mBalaz,
    photo: mbalaz,
  },
  {
    ...translate.i18n.KEY_PEOPLE.lSchmoger,
    photo: lschmoger,
  },
  {
    ...translate.i18n.KEY_PEOPLE.mkKolibjarova,
    photo: mkkolibjarova,
  },
  {
    ...translate.i18n.KEY_PEOPLE.eMichalik,
    photo: emichalik,
  },
  {
    ...translate.i18n.KEY_PEOPLE.rSlamka,
    photo: rslamka,
  },
  {
    ...translate.i18n.KEY_PEOPLE.rKovac,
    photo: rkovac,
  },
  {
    ...translate.i18n.KEY_PEOPLE.mPalicka,
    photo: mpalicka,
  },
  {
    ...translate.i18n.KEY_PEOPLE.rNaroda,
    photo: rnaroda,
  },
  {
    ...translate.i18n.KEY_PEOPLE.eNegruta,
    photo: enegruta,
  },
]
