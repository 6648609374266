// .core
import React, { useEffect, useMemo, useRef, useState } from 'react'
// components
import { AboutUs } from '../../components/AboutUs/AboutUs'
import { Article } from '../../components/Article/Article'
import Articles from '../../components/Articles/Articles'
import Media from 'components/Media/Media'
import { ScrollBar } from 'components/ScrollBar/ScrollBar'
// content
import { generateKeyPeople } from '../../content/KeyPeople/KeyPeople'
import { generateTestimonials } from 'content/Testimonials/Testimonials'
// libraries
import cx from 'classnames'
// @ts-ignore
import document from 'global/document'
// @ts-ignore
import window from 'global/window'
// styles
import css from './AboutUsPage.module.scss'
// translate
import { translate } from '../../i18n'
// utils
import { scrollToFooter } from 'utils/functions'

export interface IIsVisible {
  whatWeDo?: boolean
  innovativness?: boolean
  keyPeople?: boolean
  team?: boolean
  testimonials?: boolean
  media?: boolean
  footer?: boolean
}

interface IAboutUsPageContentProps {
  onLoadLast: () => void
  onUnloadLast: () => void
}

export const AboutUsPageContent = ({ onLoadLast, onUnloadLast }: IAboutUsPageContentProps) => {
  const keyPeopleRef = useRef<HTMLElement>(null)
  const mediaRef = useRef<HTMLElement>(null)
  const testimonialsRef = useRef<HTMLElement>(null)

  const [isVisible, _setIsVisible] = useState<IIsVisible>({
    // it is important to retain the order of these properties as it is ordered in render
    whatWeDo: undefined,
    innovativness: undefined,
    keyPeople: undefined,
    team: undefined,
    testimonials: undefined,
    media: undefined,
    footer: undefined,
  })

  const setIsVisible = (targetKey: keyof IIsVisible, shouldBecomeVisible?: boolean) => () => {
    const newIsVisible: IIsVisible = {
      [targetKey]: !!shouldBecomeVisible,
    }

    const keys = Object.keys(isVisible)
    const targetKeyIndex = keys.indexOf(targetKey)

    if (targetKey === 'footer') shouldBecomeVisible ? onLoadLast() : onUnloadLast()

    keys.forEach((key, keyIndex) => {
      if (keyIndex < targetKeyIndex) {
        isVisible[key as keyof IIsVisible] = true
        newIsVisible[key as keyof IIsVisible] = true
      } else if (keyIndex > targetKeyIndex && isVisible[key as keyof IIsVisible]) {
        newIsVisible[key as keyof IIsVisible] = false
      }
    })

    _setIsVisible((p) => ({ ...p, ...newIsVisible }))
  }

  const keyPeople = useMemo(() => generateKeyPeople(), [])
  const testimonials = useMemo(() => generateTestimonials(), [])

  useEffect(() => {
    const mediaElement = document.getElementById(location.hash.substring(1))

    if (mediaElement) {
      setTimeout(() => {
        window.scrollTo({
          top: mediaElement.offsetTop - 80,
          behavior: 'smooth',
        })
      }, 1000)
    }
  }, [])

  return (
    <div className={cx('container', css.aboutUsPage)}>
      <section className="d-flex justify-content-center mx-auto" key="s_main">
        <AboutUs
          animateFadeIn
          hideButton
          className={css.aboutUsMini}
          onUnloadThreshold={75}
          onSetVisibleCounters={(visible) => setIsVisible('whatWeDo', visible)()}
        />
      </section>
      <section key="s_whatWeDo">
        <Article
          significant
          animateIn={isVisible.whatWeDo}
          animateOut={isVisible.whatWeDo === false}
          text={translate.i18n.WHAT_WE_DO_TEXT}
          onLoad={setIsVisible('innovativness', true)}
          onUnload={setIsVisible('innovativness', false)}
        />
      </section>
      <section key="s_innovativness">
        <Article
          significant
          animateIn={isVisible.innovativness}
          animateOut={isVisible.innovativness === false}
          title={translate.i18n.INOVATIVNESS_TITLE}
          text={translate.i18n.INOVATIVNESS_TEXT}
          onLoad={setIsVisible('keyPeople', true)}
          onUnload={setIsVisible('keyPeople', false)}
        />
      </section>
      <section ref={keyPeopleRef} key="s_keyPeople" id="key-people">
        <Articles
          animateIn={isVisible.keyPeople}
          animateOut={isVisible.keyPeople === false}
          articles={keyPeople}
          button={{
            text: translate.i18n.TESTIMONIALS_TITLE,
            onClick: () => {
              if (testimonialsRef.current !== null)
                window.scrollTo({
                  top: testimonialsRef.current.offsetTop - 80,
                  behavior: 'smooth',
                })
            },
          }}
          className={css.keyPeopleArticles}
          text={translate.i18n.KEY_PEOPLE_SUBTITLE}
          title={translate.i18n.KEY_PEOPLE_TITLE}
          onLoad={setIsVisible('team', true)}
          onUnLoad={setIsVisible('team', false)}
        />
      </section>
      <section key="s_team">
        <Article
          significant
          animateIn={isVisible.team}
          animateOut={isVisible.team === false}
          text={translate.i18n.TEAM_DESCRIPTION}
          onLoad={setIsVisible('testimonials', true)}
          onUnload={setIsVisible('testimonials', false)}
        />
      </section>
      <section ref={testimonialsRef} key="s_testimonials" id="testimonials">
        <Articles
          swipeable
          animateIn={isVisible.testimonials}
          animateOut={isVisible.testimonials === false}
          articles={testimonials}
          button={{
            text: translate.i18n.SAID_ABOUT_US,
            onClick: () => {
              if (mediaRef.current !== null)
                window.scrollTo({
                  top: mediaRef.current.offsetTop - 80,
                  behavior: 'smooth',
                })
            },
          }}
          link={{
            children: translate.i18n.ALREADY_DECIDED,
            href: '#',
            onClick: () => scrollToFooter(true),
          }}
          title={translate.i18n.TESTIMONIALS_TITLE}
          onLoad={setIsVisible('media', true)}
          onUnLoad={setIsVisible('media', false)}
        />
      </section>
      <section ref={mediaRef} id="ambitas-in-media" key="s_media">
        <Media
          animate={isVisible.media}
          button={{
            text: translate.i18n.CONTACT_US,
            onClick: () => scrollToFooter(true),
          }}
        />
        <ScrollBar
          onLoadThreshold={10}
          onUnloadThreshold={90}
          onLoad={setIsVisible('footer', true)}
          onUnload={setIsVisible('footer', false)}
        />
      </section>
    </div>
  )
}
