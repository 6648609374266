// \xA0 => non breaking space (line wont break on this space)
// \r\n => start of new line, DISCLAIMER: does not work everywhere, css (white-space: pre-wrap;) needs to be set for this to work

import progLangs from 'assets/images/logos/prog_langs.webp'

const i18n = {
  ABOUT_US: `About us`,
  ABOUT_US_SUBTITLE: `Professionals in vertical farming technology, electronics, IoT sensors, hardware and software since 2009.`,
  ABOUT_US_TITLE: `Your partner for Engineering solutions.`,
  AGREE: `I Agree`,
  ALREADY_DECIDED: `Already decided for us? Get in touch now.`,
  API_FAIL: `Oops, something went wrong!`,
  ARE_YOU_INTERESTED: `Are you interested in this solution or do you have any other special use case?`,
  BACK: `Back`,
  BLOG: `Blog`,
  BLOG_NO_CONTENT: `It seems like there is nothing here yet.\r\nDon't worry! We are working hard on preparing the content for you.\r\nStay tuned for articles about IoT, engineering and prototyping and how you can leverage them to help you grow!`,
  BTN_CONTACT_US: `Contact us`,
  CASES: {
    customSoftwareSolutions: {
      url: 'custom-software-solutions',
      title: `Custom Software Solutions`,
      formTitle: `Custom Software`,
      shortTitle: `Custom Software`,
      subtitle: `Custom made software built to save costs and help you grow`,
      text: `The years of practice we have accumulated over time bring us the benefit of creating efficient and functional software solutions. Every single product is tailored for each client to meet their requirements. Our software is innovative and provides added value to clients in the form of competitive advantage.`,
      shortText: ` Innovative software solutions tailored to the specific requirements of each client to meet their requirements in order to help gain the competitive advantage.`,
      btnPhrase: `Case studies`,
      bannerTexts: [
        {
          title: `Workflow & Process\r\nManagement System`,
          items: [`Workflow digitization`, `Workflow controlling`, `Document management system`],
        },
        {
          title: `3rd Party\r\nSoftware Integration`,
          items: [
            `3rd party software integrations`,
            `API integration and provision`,
            `Data migration and synchronization`,
          ],
        },
        {
          title: `B2B B2C Platforms`,
          items: [
            `Warehouse & portfolio management`,
            `Payment & billing integration`,
            `BI reporting`,
          ],
        },
        {
          title: `Data Collection Solutions`,
          items: [
            `Data mining and analytical reporting`,
            `IoT Big Data processing`,
            `Data evaluation and distribution`,
          ],
        },
        {
          title: `Healthcare Management`,
          items: [
            `Digital reception and attendance`,
            `EHR management & integration`,
            `Custom data collection & evaluation`,
          ],
        },
        {
          title: `Asset Management\r\n& Logistic Solutions`,
          items: [
            `Localization and real-time tracking`,
            `Freight movement management`,
            `Supply chain optimization and planning`,
          ],
        },
        {
          title: `Client / Supplier Portals`,
          items: [
            `Account management`,
            `Custom Data management`,
            `Communication and Data Exchange`,
          ],
        },
        {
          title: `CRM & HR systems`,
          items: [
            `Sales process optimization and KPI’s tracking`,
            `HR data collection and reporting`,
            `Incident management system`,
          ],
        },
        {
          title: `Supportive Event Applications`,
          items: [
            `Sport events apps & games`,
            `Entertainment applications`,
            `Education applications`,
          ],
        },
      ],
      detailTexts: [
        {
          title: 'Our process',
          text: `Right after the first introduction with our clients' project proposal, we immerse ourselves into a deep analysis of the project idea. We create the basic definition of what will or will not be required of the system. After a mutual agreement, we start the process of development.`,
        },
        {
          text: `The first step is to develop a thorough and transparent technical specification that includes detailed characteristics of the project. This becomes the main part of the whole development process and ensures the smooth development of the project.`,
        },
        {
          text: `Seeing how your idea is gradually turning into a real product is one of the advantages of agile development (kanban, scrum). We apply agile principles of development in ambitas. Whichever way of development we choose, we strive to provide the client with the greatest possible added value and relevant progress to the project at all times.`,
        },
        {
          text: `All our steps and mutual communication lead to the completion of the development and the successful launch of the software solution. After the final launch, the monitoring, maintenance and continuous support process begins.`,
        },
        {
          picture: progLangs,
        },
        {
          hasBtn: true,
          btnText: 'Who trusts us',
          btnType: 'trustedBy',
        },
      ],
      references: {
        bauHolding: [
          {
            title: `Challenge`,
            subtitle: `Custom made information system`,
            text: `Construction company BAU Holding stated a demand to create a custom made information system, requiring online, fast and transparent administration. It was crucial to elaborate and implement complex information system capable to satisfy the requirements of rapidly growing company.`,
          },
          {
            title: `Solution`,
            subtitle: `What are the most essential components of the solution?`,
            text: `To summarize the process of such solution, it is only fair to mention, that the information system has been applied to all company's operations from employee attendance, through building project management, communication with suppliers and clients, billing system to connection to the accounting and bank.`,
          },
          {
            title: `Other solutions`,
            subtitle: `Beyond information system`,
            text: `Besides main information system, we have also secured additional and supporting products for the company, supporting further business development. Data gathering pages, promotional pages and other procedural, logistic and business applications or configurator applications.`,
          },
        ],
        domsnov: [
          {
            title: `Challenge`,
            subtitle: `It's smart to offer SMART`,
            text: `One of the big players in the Slovak market in the field of prefabricated houses turned to us with an interesting request. They wanted to provide clients with houses that really belong to the 21st century. This means that they wanted to become a vertical integrator and provider of SMART solutions for their homes.`,
          },
          {
            title: `Solution`,
            subtitle: `Sometimes a superstructure is the right way`,
            text: `The Bauholding application already existed, so the best way was to simply create a superstructure. An application in which clients could already monitor the construction progress of their house, they can now manage SMART devices such as: thermostat controller, electric sockets, light switches, light bulbs intensity, electricity meters, blinds control, cameras or hygrometers. All these devices are seamlessly integrated during the construction of the house and can be managed and controlled remotely. The ideal is the client's ability to choose which SMART devices they want to incorporate into their home. In addition, the hygrometer is connected to the building company, which monitors the humidity and condition of the house and can issue recommendations, that should prevent mold and other humidity related issues.`,
          },
          {
            title: `Output`,
            subtitle: `Remote access increases comfort`,
            text: `Thanks to the cooperation with Bauholding, their clients were provided with the opportunity to control and measure in their homes: electricity consumption, remotely set blinds (as heat prevention), set the thermostat in advance, turn lights on and off (eg on holiday). And since everything works conveniently from their mobile phone, we increase the comfort of their living.`,
          },
        ],
        sct: [
          {
            title: `Challenge`,
            subtitle: `2 in 1`,
            text: `In cooperation with the civic association starter, o.z. we participated in the creation of the smartXcity application. This project and the associated application, apart from the information role for the general public, also has an inclusionary role.`,
          },
          {
            title: `Solution`,
            subtitle: `How does it work and what does it bring?`,
            text: `The smartXcity application brings the possibility of using smart city solutions to improve tourism, culture or even the inclusion of a marginalized population. In the application itself, it is possible to find not just the information about important tourist monuments in Bratislava, but also information about public transport connections or other information necessary for the smooth transit of all groups of the population in the city. All based on the IoT beacon technology.`,
          },
          {
            title: `Output`,
            subtitle: `Step into the 21st century`,
            text: `The smartXcity application is a pilot project and proof of concept for smart city technologies. Thanks to such a solution, cities and regions can enter the opportunities of the 21st century, taking into an account the needs of all of their citizens while promoting the culture and its history.`,
          },
        ],
        samaSlovaktual: [
          {
            title: `Challenge`,
            subtitle: `Logistic and Asset Management`,
            text: `Slovaktual, leading producer and provider of windows and doors on the European market, assigned us with a task to come up with a more efficient customer delivery system while taking care of the assets management - racks used for the transport of products.`,
          },
          {
            title: `Solution`,
            subtitle: `Technology has once again proven its 'need for order'`,
            text: `We have created and implemented a logistics information system and a mobile application for multiple levels of users. Mobile application is designed for warehouse workers, drivers and clients of the company while each users' role has different predefined privileges and workflow handling. Bar codes on the racks themselves and a mobile app that can read them with a GPS sensor and push notifications were implemented for improving racks management and overall logistics.`,
          },
          {
            title: `Benefits`,
            subtitle: `Key features of the logistics system`,
            text: [
              `Geolocation - real-time tracking of the driver location while on a delivery to a customer, proactive evaluation of vehicle speed and location tracking for the phone battery saving mode.`,
              `Geofencing, GPS, Bar code reader, Camera, Push-notifications`,
            ],
          },
        ],
        nahradnici: [
          {
            title: `Challenge`,
            subtitle: `Free vaccines that need to be consumed`,
            text: `No strategy, whether it's the vaccination strategy or any other, is bulletproof. Those able to deal with the obstacles to ensure the implementation of the plan become successful. Vaccines, like any medicine, have an expiration date, but this is often calculated in hours. If you lack citizens to get vaccinated, you need to come up with a solution that will ensure near-100% vaccines usage and relieve vaccination centers and hospitals from administrative work.`,
          },
          {
            title: `Solution`,
            subtitle: `Efficiency saves the day`,
            text: `In cooperation with the Banská Bystrica Region, we found exactly such a solution. We created the Nahradnici.sk portal, which allowed citizens who were interested in vaccinations to register as substitutes for the vaccine. They were allowed to choose their preferred vaccination center, type of vaccine, and other options. Thanks to an efficiently created database, vaccination centers were able to call substitutes within a few minutes or hours in the event of a shortage of people and thus make full use of all prepared vaccines.`,
          },
          {
            title: `Output`,
            subtitle: `Achieving collective immunity by joint effort`,
            text: `The nahradnici.sk portal has brought benefits to many. Thanks to it, it was possible to react quickly, make use of all the prepared vaccines and last, but not least, vaccinate more than 15,000 citizens.`,
          },
        ],
        terminyorg: [
          {
            title: `Challenge`,
            subtitle: `Panic and Chaos During a Pandemic`,
            text: `The Covid-19 pandemic has hit the world. However, not all countries have been able to meet this challenge in the same way. The aim was to alleviate the chaos that occurred in Slovakia, and to provide accurate information on such vital matters as the available vaccination dates.`,
          },
          {
            title: `Solution`,
            subtitle: `Clear and Simple`,
            text: `The quick reaction of our team provided help to thousands of people. Thanks to the cooperation with the national health information center (NHIC), we were able to create a website with a quality UI / UX, readable for each user, which contained a list of available terms and vaccination centers.`,
          },
          {
            title: `Output`,
            subtitle: `Relieving the onslaught on national servers`,
            text: `terminy.org not only provided an easier way to select a vaccination date for each user, but also relieved NHIC-managed servers. Each user was able to check the availability of their vaccination center without the need to constantly update the NHIC website.`,
          },
        ],
        csob: [
          {
            title: `Challenge`,
            subtitle: `Run app in a record time`,
            text: `The company organizing the annual CSOB Marathon approached us with a request to develop a mobile application. It was intended to provide runners and fans with all the necessary information about the runs and accompanying activities in which they could participate.`,
          },
          {
            title: `Solution`,
            subtitle: `What is the most important information?`,
            text: `One of the most important steps was to determine which information to store and how the application should share them with its users. The presentation of individual propositions of runs, maps, registrations and start lists was essential. Last, but not least, it was necessary to provide the possibility of tracking individual runners.`,
          },
          {
            title: `Output`,
            subtitle: `Availability. Transparency. Awareness.`,
            text: `Despite the short time we had, we have managed to deliver the application in time for CSOB Marathon ‘19 and we are looking forward to the successful years ahead of us!`,
          },
        ],
        o2: [
          {
            title: `Challange`,
            subtitle: `Efficient way of announcing competition results `,
            text: `O2 Company requested the platform covering efficient and fast evaluation of score and ranking of the individual competitors during the special event for O2 employees. Basic requirement was to create accessible software available for hostesses responsible for ranking and the final score. Challenge accepted!`,
          },
          {
            title: `Solution`,
            subtitle: `Re-active app`,
            text: `We have created a web app combining administrative interface and front-end part for hostesses. Administrative basis of the app assured the possibility to re-use the application for following years' events in the future. Reactive technology ReactJS was used for the hostess part of the app providing easy, interactive and beautiful visualisation for comfortable usage.`,
          },
          {
            title: `Output`,
            subtitle: `Graph as the ultimate solution`,
            text: `The application output is a well-arranged chart recording the score for individual teams. Thanks to the reactive technology used during the development, data are updated in the real time and immediately after each score ranking.`,
          },
        ],
        oktagon: [
          {
            title: `Challange`,
            subtitle: `Beauty in simplicity`,
            text: `Content management can become a nightmare as the content grows. OKTAGON approached us with a request to create an administration system for their mobile application, which would offer easier and more intuitive content management.`,
          },
          {
            title: `Solution`,
            subtitle: `The spine of the system is the foundation`,
            text: `We fulfilled the requirement by creating the so-called OKTAGON Backbone system, which serves the company's employees as a tool for managing the overall content of the application. New functionalities are constantly being added and the system allows employees to do more and more.`,
          },
          {
            title: `Output`,
            subtitle: `It all depends on the right tool`,
            text: `The right tool for communication, administration or management can bring financial as well as non-financial benefits into any process. Thanks to OKTAGON Backbone, OKTAGON employees are able to conveniently and easily manage and administer their mobile application and thus bring their fans interesting and exclusive content.`,
          },
        ],
        merchyou: [
          {
            title: `Challange`,
            subtitle: '',
            text: `MerchYOU, a company engaged in the printing of T-shirts and other textile products, asked us to create a specialized platform for the sale of merch products of individual clients.`,
          },
          {
            title: `Solution`,
            subtitle: '',
            text: `In cooperation with MerchYou, a speciialized and highly modifiable platform has been created, which allows MerchYOU clients to customize their website according to their own brand characteristics. After creating their "website", clients are able to further resell their own merch made by MerchYOU.`,
          },
          {
            title: `Output`,
            subtitle: '',
            text: `Elegant creation of a new e-shop in an administrative system, which helps a new e-shop to be up and running free of charge within one day. The big advantage is the automatic sending of orders directly to MerchYOU, where they are subsequently fulfilled and sent to the customer.`,
          },
        ],
        onlineApp: [
          {
            title: `Challenge`,
            subtitle: `21st century accounting is online`,
            text: `Arisan, a company providing accounting services on the Slovak, Czech and Bulgarian markets, has brought up a demand to provide its customers with better insight into their company's accounting records. Given the prior fact, that they work with local 'Pohoda Software', our target was to provide partial virtualization of this software into the online format and to expand on the reporting capabilities and solutions the original software did not provide. Put simply - bring accounting online.`,
          },
          {
            title: `Solution`,
            subtitle: `Web platform as an extension of an accounting software`,
            text: `We have created the web application as an extension of the 'Pohoda' accounting software. All operations included in web app are reflected back to the Pohoda software in real time, securing the immediate availability to the accountant. Connection is mutual, client is given a prompt reply to the changes done in the agenda, visible back in the reporting application.`,
          },
          {
            title: `Extension of the extension`,
            subtitle: `...and adding even more`,
            text: `Implementation of the basic solution resulted in complex user platform, utilized not only by the clients, but also by Arisan itself. TimeTracker - software integrated into the cloud application is a bonus feature and a desktop app was created with the scope to provide precise working attendance records of employees able to work in offline mode. Company was given overall high-quality tool to evaluate profitability and efficiency of employees.`,
          },
        ],
        samsung: [
          {
            title: `Challenge`,
            subtitle: `How to promote the latest Samsung phones?`,
            text: `Samsung's task was very interesting - to find a way to promote their latest models Samsung A8 and Samsung S9+ and their innovative features. Initial idea was to advertise the functions of the new models directly during unique events using latest devices. A8 key feature is high-quality camera with face detection and S9+ prides its impressive slow-motion video capture.`,
          },
          {
            title: `Solution`,
            subtitle: `Mobile app in the form of a game`,
            text: [
              `In order to promote A8 model we created a mobile app in the form of a game. Puzzle revealing the photo of the players was the entertaining way how to try out the camera. It was an interesting way of how to promote the phone and entertain a customer.`,
              `Samsung S9+ was advertised by a simple web app. People could check the quality of the latest pieces during Samsung Special Events and create their own slow motion videos, accessible for the "actors" directly via the application and ready to be shared on social media to their friends.`,
            ],
          },
          {
            title: `Output`,
            subtitle: `What did we achieve?`,
            text: `We established the possibility to try the latest, innovative features of Samsung's devices. Data collection provided the company with useful information about potential clients and the #funFactor was a bonus entertainment feature.`,
          },
        ],
        telekom: [
          {
            title: `Challenge`,
            subtitle: `Run app in a record time`,
            text: `As with the CSOB client, the app for Telekom and its annual Telekom Night Run was intended to provide runners and fans with all the necessary information about running and accompanying activities and ECO activities they could participate in.`,
          },
          {
            title: `Solution`,
            subtitle: `What is the most important information?`,
            text: `One of the most important steps was to determine which information and how the application should share it with its users. The presentation of individual propositions of runs, maps and also ECO activities connected with the whole event was essential. Last but not least, it was necessary to share the possibility of tracking individual runners.`,
          },
          {
            title: `Output`,
            subtitle: `Availability. Transparency. Awareness.`,
            text: `An application in which users can find all the necessary information about runs, activities, ECO activities, but they can also read an interesting running magazine available directly in the application.`,
          },
        ],
        webReport: [
          {
            title: `Challenge`,
            subtitle: `How to make media search faster?`,
            text: `How to make online search faster and more approachable? Everyday practice has been established for a tool for fast online media searching. Instant and automatic reporting from hundreds web portals.`,
          },
          {
            title: `Solution`,
            subtitle: `What is Webreport able to do?`,
            text: `Business model was clear from the beginning - save peoples' time and provide them with a reliable tool working for them. We developed such solution for The News Agency of the Slovak Republic (TASR) – Webreport. The web application is gathering information from more than a 1000 media sources all over the world. Based on the RSS feeds and content indexing, Webreport is capable of a real-time monitoring of a new content within covered media and report desired topic according to key words.`,
          },
          {
            title: `Long-term solution`,
            subtitle: `Support. Innovation. Durability.`,
            text: `Webreport has been maintained for many years and and is being further developed continuously, securing its top-ranked position within the monitoring systems' market. Many banks, ministry departments, authorities and other companies apprise our solution for daily newest media feed.`,
          },
        ],
        webSwing: [
          {
            title: `Challenge`,
            subtitle: `Custom made information system`,
            text: `Webswing has asked us to help solve the problem of managing clients and their queries. The main problem was that the client communicated from multiple email addresses, which often led to loss of information.`,
          },
          {
            title: `Solution`,
            subtitle: `CRM system customized to the client’s needs`,
            text: `The solution we provided to the company consisted in creating a customized CRM system that automatically assigns individual users to specific organizations (clients).`,
          },
          {
            title: `Output`,
            subtitle: `Unified communication`,
            text: `In this way, the communication with the client is organized and unified, and the CRM system user sees the entire communication, from all the email addresses of the organization. There is no loss of information.`,
          },
        ],
        ppe: [
          {
            title: `Challenge`,
            subtitle: `How to save a chaotic market?`,
            text: `After the start of the Covid-19 pandemic, it was not long before the market for personal protective equipment such as masks, respirators, and gloves became an incredibly dynamic, chaotic, and volatile market. This was caused not only by the lack of products on the market, but especially by the number of new entrants, who in many cases did not trade with real products, but took advantage of the bad situation and thus tried to commit fraud.`,
          },
          {
            title: `Solution`,
            subtitle: `PPE Center platform - a verification authority`,
            text: `The solution to this situation is the PPE Center platform, which was developed to create a marketplace for legitimate PPE traders and to enable them to feel safe. Its role is also to do the "dirty work" for its users and to verify the legitimacy of other users. This follows a business model based on mutual trust and cooperation.`,
          },
          {
            title: `Output`,
            subtitle: `Verification as a significant value`,
            text: `The verification services provided by the PPE Center platform provide significant value to the registered users and merchants. The platform includes complex processes and systems ensuring the identification of legitimate and trustworthy traders.`,
          },
        ],
        ravalTrade: [
          {
            title: `Challenge`,
            subtitle: `Creating an almighty platform?`,
            text: `Raval Trade, the exclusive reseller of Beko appliances on the Slovak market, asked us to create a B2B platform to manage products and make sales available to wholesalers. Another requirement was to interconnect the created B2B platform with TP SOFT software for warehouse management and synchronization of wholesalers, products and orders.`,
          },
          {
            title: `Solution`,
            subtitle: `Everything in one place.`,
            text: `By creating a B2B platform that can be accessed by RavalTrade employees as well as wholesalers, we have ensured smooth product sales and automated synchronization with warehouse management software. Warehouse workers are able to scan product serial numbers using a mobile phone, for real-time status of products in stock, the possibility of storage and removal of products and, last but not least, registration of production products. Wholesaler management allows you to individually assign discounts and price levels to products and manage your own subsidiaries.`,
          },
          {
            title: `Output`,
            subtitle: `Order and speed are essential business benefits.`,
            text: `Clear lists and databases of all orders, wholesalers and products bring order and simplicity to the organization's processes. It also benefits wholesalers to order missing goods literally in just a few clicks.`,
          },
        ],
        beko: [
          {
            title: `Challenge`,
            subtitle: `Beko sales point`,
            text: `Creation of e-shop and administration system for its control and general administration. The aim was to connect to the already created B2B system for Raval Trade together with synchronization to TP SOFT software.`,
          },
          {
            title: `Solution`,
            subtitle: `Cross connection of B2B and B2C in one platform`,
            text: `By combining the original B2B solution with the planned e-shop solution, the B2C platform was created, which shares products with the B2B platform and thus simplifies product management and sales.`,
          },
          {
            title: `Output`,
            subtitle: `Unified system for extensive management`,
            text: `The main advantage of the created project is a unified administration system, in which Raval Trade and Beko employees can operate both the B2B platform and the B2C platform and their customers, orders and manage the entire e-shop solution.`,
          },
        ],
        elica: [
          {
            title: `Challenge`,
            subtitle: `Elica sales point`,
            text: `Creation of e-shop and administration system for its control and general administration. The aim was to connect to the already created B2B system for Raval Trade together with synchronization to TP SOFT software.`,
          },
          {
            title: `Solution`,
            subtitle: `Cross connection of B2B and B2C in one platform`,
            text: `By combining the original B2B solution with the planned e-shop solution, the B2C platform was created, which shares products with the B2B platform and thus simplifies product management and sales.`,
          },
          {
            title: `Output`,
            subtitle: `Unified system for extensive management`,
            text: `The main advantage of the created project is a unified administration system, in which Raval Trade and Beko employees can operate both the B2B platform and the B2C platform and their customers, orders and manage the entire e-shop solution.`,
          },
        ],
        nudch: [
          {
            title: `Challenge`,
            subtitle: `New communication channel between hospital and parents`,
            text: `NÚDCH - The National Institute of Pediatric Diseases in Bratislava has launched a call for the creation of a new communication channel between the hospital and parents (with pediatric patients), which would enable them to reduce examination times and thus the costs of providing health care. Thanks to the new platform, the hospital was able to better inform patients and at the same time prevent unnecessary and stressful visits to the hospital.`,
          },
          {
            title: `Solution`,
            subtitle: `Creation of smart mobile application`,
            text: `To solve the task, we chose a form of mobile application that is user-friendly and fast. It enables the creation of profiles for children and continuous tracking of their health status (allergies, antibiotics, vaccinations and various other aspects of their health). A big advantage is the sophisticated decision algorithm system, which enables quick initial diagnosis and helps to guide parents through the next steps accordingly. Thanks to the fast algorithm, parents can decide in time to visit a doctor or provide professional care from home, according to the applications' recommendations.`,
          },
          {
            title: `Benefits`,
            subtitle: `Everyone benefits from technology`,
            text: `From the perspective of parents as users, the application has brought a tool for accelerated diagnosis of their children and helps them make better decisions about the next steps to take. From the hospitals' point of view, we were able to reduce unnecessary hospital visits by 70% and, conversely, increase the acceptance of the application by its end users by 70%. With this 100% patient centric solution, the application provides benefits to the all involved parties.`,
          },
        ],
        ibdDiary: [
          {
            title: `Challenge`,
            subtitle: `To create an app for patients suffering from IBD`,
            text: `Health problems make life difficult for almost everyone. In this case, the client approached us with a project aimed at creating a mobile application that would make life easier for patients with IBD. This application was to serve as a personal assistant to the patient, that would record various aspects of their health and help them manage the course of the disease. Conversely, the patients' condition could be monitored continuously by the doctor, which would significantly shorten lengthy and expensive examinations.`,
          },
          {
            title: `Solution`,
            subtitle: `Step by step towards telemedicine`,
            text: `In order for the application to really serve as a personal assistant and at the same time as a basis for the doctors' examination, it is necessary for the application to collect all the necessary patient data and provide various useful functions. This is ensured by questionnaires, a calendar of examinations with results, alerts or other functionalities. Thanks to extensive statistics, the doctor can monitor the development of the patients' condition over time and thus better determine further treatment.`,
          },
          {
            title: `Benefits`,
            subtitle: `Better care with lower costs`,
            text: `The basics of telemedicine, and therefore the sharing of patient data with the physician, allow the physician to check the patients' condition at any time. Accuracy, development, but also the timeliness of information are very important not only in determining the diagnosis, but especially in the overall treatment of the patient. Thanks to the use of this application, we managed to achieve a 90% acceptance rate of the application by users and the client to reduce the duration of medical examinations by 20%. Thanks to a solution focused 100% on the patient, even a doctor can provide flawless and personalized care.`,
          },
        ],
      },
    },
    logisticSolutions: {
      references: {
        samaSlovaktual: [
          {
            title: `Challenge`,
            subtitle: `Logistic and Asset Management`,
            text: `Slovaktual, leading producer and provider of windows and doors on the European market gave us task to arrange more efficient customer delivery system and simultaneously to cover asset management - racks used for the transport system of the product.`,
          },
          {
            title: `Solution`,
            subtitle: `Techonology has once again proven its need for order`,
            text: `Information logistic system and mobile app were created and implemented in order to meet the demand. Mobile app is designed for storage keepers, drivers and clients of the company while each of the user's role has different rights and workflow handling. Bar codes and bar code reader implemented in the cell phone, GPS sensor and push notifications were used with purpose to sustain the rack management and overall logistics.`,
          },
          {
            title: `Benefits`,
            subtitle: `Key features of the logistics system`,
            text: [
              `Geolocation - active tracking of the driver in the real time regarding customer delivery, vehicle speed evaluation and location control regarding battery saving mode.`,
              `Geofencing, GPS, Bar code reader, Camera, Push-notification`,
            ],
          },
        ],
      },
    },
    ambience: {
      url: 'ambience',
      title: 'ambience IoT ecosystems',
      formTitle: 'IoT\r\nambience',
      subtitle: `Being connected means to be well informed`,
      text: `Nowadays everything needs to be tracked, measured, evaluated and planned accordingly. Data gathered from various sensors and IoT devices give companies deeper insight of what is going on behind the scenes. We are now standing at the very edge of the technological breakthrough, where proper data can make the difference between success and failure.`,
      btnPhrase: `Case studies`,
      shortText:
        'Cutting-edge sensoric and data-based ecosystems for various industries all over the world. Keeping the client well informed and aware of data peaks to reduce the reaction time to incidents.',
      bannerButton: 'Discover More',
      bannerTexts: [
        {
          title: `Condition monitoring`,
          items: [
            `Smart estate management`,
            `Sensor based alarm triggering`,
            `Real time status monitoring`,
          ],
        },
        {
          title: `Smartcity Solutions`,
          items: [
            `Context of the physical world`,
            `Seamless information stream`,
            `Minority groups support`,
          ],
        },
        {
          title: `Machines’ maintenance scheduling`,
          items: [
            `Real-time machines’ monitoring`,
            `Ensuring continuity of production`,
            `Proactive and seamless reporting`,
          ],
        },
        {
          title: `Herd monitoring`,
          items: [
            `Identification of behavioral changes`,
            `Early disease detection`,
            `Breeding cost reduction`,
          ],
        },
        {
          title: `Customer services enhancement`,
          items: [
            `Early detection of incoming customer`,
            `Seamless presence detection`,
            `Virtual data sharing`,
          ],
        },
        {
          title: `Data gathering`,
          items: [`Custom data gathering for data-based decision making system and automation`],
        },
      ],
      detailTexts: [
        {
          text: `"As of 2020, there is around 20 billion IoT devices connected to the network, most of which are adding significant value to business growth or households well-being.`,
          style: 'significant',
          hasBtn: true,
          btnText: 'Discover More',
        },
        {
          text: 'Let IoT penetrate your business and help you grow.',
          style: 'bold',
          hasBtn: true,
          btnType: 'cases',
        },
      ],
      references: {
        ambience: [
          {
            title: `Challenge`,
            subtitle: `Hardware and software integration`,
            text: `We issued ourselves a challenge - to create environmental sensors on a custom hardware platform with a cloud management and data analysis to go with it. Sensor hardware is focused on precise data gathering, rugedness and long battery life. Cloud management required not only a user friendly interface, usefull data representation and full control of the attached hardware (sensors), but also the ability to export commonly used industrial data & asset management systems as well as through an open API.`,
          },
          {
            title: `Solution`,
            subtitle: `All environmental data under one roof`,
            text: `The developement process was split into two parts - sensor hardware developement - focusing on sensors and software developement - focusing on data management system and an app for on-site direct hardware interfacing. Sensor developement has been focused on building a flexible hardware platform to build various environmental sensors on. While on the other hand, the software part has been focused on per-client isolation, an optimal data processing and representation, instant notifications in case of critical conditions, open API standards and data exports to wide variety of formats to suit the needs of all clients.`,
          },
          {
            title: `Output`,
            subtitle: `Strong foundations`,
            text: `The main result of our work is a strong foundation for future IoT sensors development. We can quickly develop and integrate custom sensors based on client's requirements and export the data to widely used management systems or through an open API. All collected sensory data is gathered in Europe, "under one roof", with a user friendly UI and useful views.`,
          },
        ],
        dss: [
          {
            title: `Challenge`,
            subtitle: `Environmental monitoring`,
            text: `A social care home approached us with an issue of monitoring environmental data within their facility. At the time, this was being done manually, which lead to imprecise measurements and slow reaction times to incidents. They have noticed a drop in the quality of their services as well as increased energy and resource wasting, that had an impact on both ecological and economic aspects of their everyday operations. The real challenge lied in the extreme conditions the sensors have to operate in, whether it comes to a freezer with low temperatures or bathrooms with high humidity.`,
          },
          {
            title: `Solution`,
            subtitle: `The ambience ecosystem`,
            text: `While working on this solution, we split the process into two main parts - development of hardware and firmware and software development. The main focus of hardware and firmware development was to create a rugged sensor that could withstand extreme temperatures and humidity, but still remain visually appealing and easy to control and use. The rest of the team worked in parallel to deliver a user-friendly interface for data analysis, per-client isolation, categorization of devices per facility, instant notifications via SMS or e-mail and data exports.`,
          },
          {
            title: `Output`,
            subtitle: `Optimized operations`,
            text: `The result of our work is a rugged sensor with a battery life of up to 4 years and a SaaS product that can instantly notify relevant people about critical conditions, including, but not limited to malfunction of the HVAC, improperly closed fridge door or a warehouse window being left opened, and many more. Apart from other features, such product improves human resources operations as there is no need for regular 'manual' check-ups, no food goes bad in case of an exposure to improper humidity or temperature levels and last, but certainly not least, it also improves living conditions for patients and the staff.`,
          },
        ],
      },
    },
    verticalFarming: {
      url: 'vertical-farming',
      title: 'Custom Vertical Farming',
      formTitle: 'Vertical\r\nFarming',
      subtitle: `You dream it, we design and deliver`,
      text: `Engineering vertical farms that blend horticulture with agricultural technology for fast, year-round, sustainable production in controlled environments, ensuring quality and predictability with minimal space and water usage.`,
      btnPhrase: `Case studies`,
      shortText:
        'Engineering vertical farms that blend horticulture with agricultural technology for fast, year-round, sustainable production in controlled environments, ensuring quality and predictability with minimal space and water usage.',
      bannerButton: 'Discover More',
      bannerTexts: [
        {
          title: `Design & Planning`,
          items: [`3D CAD & Generative Design`, `Architecture Design`, `Consultations`],
        },
        {
          title: `Building & Installation`,
          items: [`Hardware development`, `Custom electronics`, `On-site installation`],
        },
        {
          title: `Environment control`,
          items: [`Custom IoT sensors`, `HVAC`, `Software & Data analytics`],
        },
        {
          title: `Monitoring & Farm automation`,
          items: [`Remote control`, `Cloud management`, `Workflow adjustments`],
        },
        {
          title: `Optimizations & Innovations`,
          items: [`Further automation`, `Energy management`, `Waste management`],
        },
        {
          title: `Continuous support`,
          items: [`Technical assistance`, `Training & Maintenance`, `Post-installation services`],
        },
      ],
      whoIsItFor: [
        {
          title: 'Large farmers',
          items: [
            'Advanced vertical farm solutions tailored for large-scale agricultural operations.',
          ],
        },
        {
          title: 'Pharmaceutical & Beauty',
          items: ['Cutting-edge cultivation technology for pharmaceutical and beauty industries.'],
        },
        {
          title: 'Research & Startups',
          items: ['Innovative approach supporting research and startup ventures.'],
        },
      ],
      whyShouldYouDoIt: [
        {
          title: 'Sustainability',
          items: [
            'Promoting sustainability by significantly reducing water consumption, minimizing the need for land and reducing transport-related emissions.',
          ],
        },
        {
          title: 'Consistency',
          items: [
            'Offering year-round, consistent quality crop production regardless of external climate conditions.',
          ],
        },
        {
          title: 'Predictability',
          items: [
            'Enabling predictable yields and precise harvest timing, and therefore improving planning and supply reliability.',
          ],
        },
        {
          title: 'High Quality',
          items: [
            'Ensuring high-quality produce through a precisely controlled growing environment.',
          ],
        },
        {
          title: 'Local Supply',
          items: [
            'Facilitating fresh, local produce supply, reducing reliance on long-distance transportation.',
          ],
        },
        {
          title: 'Efficiency',
          items: [
            'Vertical farming offers solutions to challenges, such as water scarcity or land constraints, which makes it more efficient than traditional agriculture.',
          ],
        },
      ],
      bannerTextsTitle: 'Services',
      faq: [
        {
          title: 'Upfront Investment',
          rows: [
            {
              title: 'How much does it cost to build a vertical farm?',
              content:
                'The cost can range significantly depending on <b>size</b> and <b>technology</b>. Small-scale setups could cost a few thousand euros, while large/commercial-scale operations might require <b>millions</b>. Factors like the cost of land, building modifications, lighting, climate control systems, and hydroponic or aeroponic systems all contribute to the overall cost.',
            },
            {
              title: 'How much electricity do I need to build a vertical farm?',
              content:
                "The primary electricity use is for lighting, climate control (<b>HVAC</b>), and running <b>water pumps</b>. It depends on the size of the setup. It's essential to calculate energy needs based on the size of the farm and the specific equipment used.",
            },
            {
              title: 'How much water do I need when running a vertical farm?',
              content:
                'Vertical farming is water-efficient, often using up to <b>95% less water</b> than traditional farming. The exact amount depends on the size of the farm and the crops being grown, yet still less than a traditional farm of a comparable scale.',
            },
            {
              title: 'How much space do I need to build a vertical farm?',
              content:
                'Vertical farming is space-efficient, allowing for <b>more produce per square meter</b> than traditional farming. Large commercial operations may require tens of thousands of square feet. The height of the space is also a factor, as more layers of crops can be added in taller spaces.',
            },
            {
              title: 'How much does it cost to maintain the vertical farm?',
              content:
                'Maintenance costs include electricity, water, nutrients, seed replenishment, labor, and occasional equipment repair or replacement. These costs will vary based on the scale and efficiency of the farm. To uncover more details, <a href="#footer-section">contact us</a>!',
            },
          ],
        },
        {
          title: 'Production, Delivery & Support',
          rows: [
            {
              title: 'How to start building a vertical farm?',
              content:
                'First of all, it is important to have a business plan and research done. Fortunately, we offer comprehensive consultations about all the steps to guide you while bringing your idea to reality. Don\'t hesitate to <a href="#footer-section">contact us</a>!',
            },
            {
              title: 'What do I need before I start building a vertical farm?',
              content: `Before entering the journey of building a vertical farm, it is crucial to ensure that you have a <b>market analysis</b> and <b>feasible business plan</b>. Not to forget, it's also essential to be geared up for adequate funding. Alongside financial readiness, conducting a thorough <b>market analysis</b> is vital to understand your potential customers and the competitive landscape. It's also important to identify and establish relationships with reliable <b>suppliers</b>, which are key to the smooth operation and long-term sustainability of your vertical farm. For more information, <a href="#footer-section">contact us</a>!`,
            },
            {
              title: 'How long does it take to build a vertical farm?',
              content:
                'The time frame varies widely depending on the scale of the farm. Small setups can be built in a few weeks, while larger commercial farms might take <b>several months to a year</b> or more to plan, construct, and become <b>fully operational</b>.',
            },
            {
              title: 'What can ambitas do for me when building a vertical farm?',
              content:
                'Our company provides a <b>full package</b> from design and planning, building and installation to continuous support and maintenance. For more information, <a href="#footer-section">contact us</a>!',
            },
            {
              title: 'Do you provide long term support?',
              content:
                'Yes, <b>ambitas</b> could offer long-term support including technical assistance, maintenance services, system upgrades, and ongoing training to ensure your vertical farm remains <b>efficient</b> and <b>productive</b>. Don\'t hesitate to <a href="#footer-section">contact us</a>!',
            },
          ],
        },
        {
          title: 'Growing and Harvesting',
          rows: [
            {
              title: 'What product should I choose to grow in a vertical farm?',
              content:
                'The choice of crops to grow in a vertical farm depends on several factors, including market demand or climate control capabilities. Common choices are <b>leafy greens</b>, <b>herbs</b>, and <b>microgreens</b> due to their shorter growth cycles and lower light requirements. However, with advanced technology, you can also grow fruits, root vegetables, flowers and <b>specific herbs</b> and spices that are rare, hard to grow and have huge value for customers.',
            },
            {
              title: 'Do you provide automation in vertical farming?',
              content:
                'Yes, we provide automation solutions in vertical farming. This can include automated irrigation systems, climate control and lighting. We are also working on some <b>automation processes</b>, which concern harvesting or processing of cultivated crops, nonetheless, these processes are specific and adapted according to the time. <b>Automation</b> helps in reducing labor costs and improving the efficiency and consistency of crop production. For more information, <a href="#footer-section">contact us</a>!',
            },
            {
              title: 'Do you support us with growing in a vertical farm? ',
              content:
                'We <b>offer support</b> for growing in a vertical farm. This support can range from consultancy on the best practices for planting and maintenance, bringing a favorable price offer, to providing technology for monitoring and optimizing plant growth.',
            },
            {
              title: 'How large can a cultivation area in a vertical farm be?',
              content:
                'The size of a cultivation area in a vertical farm can vary greatly. It largely depends on the available space, ceiling height, and the type of vertical farming system implemented. Some vertical farms can be as compact as a few tens of square meters, while larger commercial operations might cover several hundreds to thousands of square meters.',
            },
            {
              title: 'How tall can the vertical farm be?',
              content:
                'The height of a vertical farm is generally limited by the structure of the building in which it is situated. Vertical farms are often established in warehouses or custom-designed buildings, with heights ranging about <b>3 meters and more</b>. The configuration of the vertical farming system and the varieties of crops being cultivated also play a role in determining the optimal height.',
            },
            {
              title: 'Are vertical farms safe from common agricultural pests and diseases?',
              content:
                'Vertical farms are <b>generally less vulnerable</b> to common agricultural pests and diseases due to their controlled environment. The indoor setting allows for better management of growing conditions and typically requires less or no pesticide use at all. However, they are not completely immune, and good hygiene practices and regular <a rel="noreferrer" target="_blank" href="https://ambience.ambitas.org/">monitoring</a> are essential to maintain a healthy crop.',
            },
          ],
        },
        {
          title: 'Others',
          rows: [
            {
              title: 'Can I visit some of the vertical farms you have built?',
              content:
                'Yes, we <b>welcome visits</b> to our vertical farms. We can arrange tours of facilities that showcase our technology and systems in action. This would give you a firsthand look at how our vertical farms operate and the various technologies we implement. To arrange a tour, <a href="#footer-section">contact us</a>!',
            },
            {
              title:
                'Does our company offer any consulting services for setting up vertical farms?',
              content:
                'Absolutely, we offer <b>comprehensive consulting services</b> for setting up vertical farms. Our expertise ranges from helping you choose the right crops and technologies to designing the layout of your farm for optimal efficiency. For more information, <a href="#footer-section">contact us</a>!',
            },
            {
              title: 'Do you provide training in the field of vertical farming?',
              content: `Yes, we provide <b>extensive training</b> in vertical farming. Our training programs cover various aspects, including system operation, crop management, and troubleshooting. We ensure that our clients are well-equipped with the knowledge and skills needed to efficiently manage their vertical farms. Don't hesitate to <a href="#footer-section">contact us</a>!`,
            },
            {
              title: 'How profitable is an automated vertical farm?',
              content:
                'The profitability of an automated vertical farm depends on various factors such as crop selection, market demand, and operational efficiency. Generally, due to lower water and space requirements, and potentially reduced labor costs, automated vertical farms <b>can be quite profitable</b>. Nevertheless, this all depends on your specific business plan. Should you require assistance in calculating your ROI, do not hesitate to <a href="#footer-section">contact us</a>, and we can work through it together!',
            },
            {
              title:
                'Are there any successful case studies or testimonials from our existing clients?',
              content:
                'Yes, we have successful case studies and client testimonials. These reflect our diverse applications, from small-scale urban producers to large-scale commercial operations. They showcase the effectiveness of our systems, the quality of our produce, and the satisfaction of our clients with our services and support.',
            },
          ],
        },
      ],
    },
    engineering: {
      url: 'engineering',
      title: 'Engineering',
      metatitle: 'Engineering, hardware, software & firmware',
      formTitle: 'Complex\r\nEngineering',
      subtitle: 'Custom hardware, software, firmware\r\ndesign & development',
      btnPhrase: `Case studies`,
      shortText:
        "Engineering of custom hardware & software solutions. Ranging from 'napkin sketches', CAD designs and manufacturing up to tying up a ribbon to the delivered product.",
      text: "Engineering of custom hardware & software solutions. Ranging from 'napkin sketches', CAD designs and manufacturing up to tying up a ribbon to the delivered product.",
      references: {
        velesFarming: [
          {
            title: `Challenge`,
            subtitle: `From A to Z`,
            text: `The VelesFarming startup approached us with a real challenge - to design and manufacture a vertical saffron farm. The main vision of this startup was to create a modern farm of the 21st century - ecologic, reliable, highly automated and connected to the internet. Starting from scratch, we went through CAD designs, consultations, design and manufacturing of hardware and software development.`,
          },
          {
            title: `Solution`,
            subtitle: `Research, development and implementation`,
            text: `Bringing this complex project to reality required the application of expert knowledge in both software and hardware fields. We also had to ensure effective communication between the design team, hardware suppliers and manufacturers and the team responsible for the final assembly. Apart from efficiently managed human resources, the design and implementation of custom electronics with a high level of hardware was required for efficient management of electricity and water supply, brought together by firmware and software integration.`,
          },
          {
            title: `Output`,
            subtitle: `Agronomy of the 21th century`,
            text: `The result is a modern, ecological, and economic vertical farm providing almost 20 square meters on a footprint of less than 4 square meters. Although primarily focused on saffron, it enables efficient cultivation of a wide variety of plants. Cloud integration ensures a safe operation without any surprises, with added benefit of efficient management without the need for an on-site intervention. Equipped with latest sensors for everything from temperature to pH and CO2, it helps to create perfect growing conditions and respond to environmental changes beyond the capabilities of a typical 'analog' farm.`,
          },
        ],
        velesFarming2: [
          {
            title: `Challenge`,
            subtitle: `From A to Z`,
            text: `As for the first farm, Veles Farming once again relied on us to design and manufacture a saffron vertical farm, but this time, ending up with building <b>the largest vertical farm</b> for growing <b>saffron</b> on this <b>planet</b>! The main vision remained the same, but the goal was to improve upon things that could be upgraded compared to the previous model. We started again with <b>CAD design</b>, consultations, and began preparing the hardware manufacturing process and software development. (<i>Explore more about <a href="/blog/what-should-you-consider-before-building-a-vertical-farm">what you should consider before building a vertical farm</a> in the new article!</i>)`,
          },
          {
            title: `Solution`,
            subtitle: `Research, development and implementation`,
            text: `​​With the help of expert knowledge in <b>hardware, software</b> and efficient communication between the various sectors, we have pioneered the creation of a vertical farm using a <b>more standardized</b> approach in construction, employing components that follow a common structure. This not only ensures <b>consistency</b> but also facilitates <b>scalability</b> with <b>efficiency</b>. In our quest for innovation, we have achieved a significant milestone in making the vertical farm more <b>energy-efficient</b>. By optimizing various processes, we now require notably less energy consumption per bulb. This transformation translates into a solid reduction in the overall energy consumption. Compared to the <a href="/solutions/vertical-farming/case-study/vertical-farm">previous model</a>, we have developed a <b>new centralized control unit</b>, making this farm more efficient than any other before. Thanks to all these <b>improvements</b>, we have managed to <b>optimize production costs</b> and transform this complex concept into existence.`,
          },
          {
            title: `Output`,
            subtitle: `Agronomy of the 21th century`,
            text: `This project resulted in a <b>sustainable, innovative</b> and <b>cost-effective</b> vertical farm in the heart of Europe providing <b>350 square meters</b> of cultivation area for growing saffron which makes it the largest farm for growing this crop. It creates an ideal environment for growing <b>premium-grade saffron</b> or a diverse selection of other crops (<i>To learn more about <a href="/blog/what-is-the-best-profitable-crop-for-vertical-farming">profitable crops for vertical farming</a>, read our recent article!</i>). We cannot forget to mention tracking via our <a rel="noreferrer" target="_blank" href="https://ambience.ambitas.org/">IoT sensors</a>, including parameters such as <b>CO2</b> levels, <b>temperature</b>, <b>humidity</b>, <b>pH</b>, and <b>leaf surface temperature</b>. Thanks to these newest IoT <b>sensors</b>, <b>cloud</b>, <b>remote</b> management and the integration with <b>HVAC</b>, <b>humidifiers</b> and <b>watering system</b>, the smooth running of the operation is ensured, and we have managed to create another vertical farm project that contributes to revolutionizing the future of agriculture.`,
          },
        ],
      },
      bannerTexts: [
        {
          title: `Electronics design`,
          items: [`Power architecture design`, `Schematics design`, `PCB Design`],
        },

        {
          title: `Construction design`,
          items: [
            `Technical drawing`,
            `3D CAD & Generative Design`,
            `CAE Simulations (Stress, CFD)`,
          ],
        },
        {
          title: `Architecture design`,
          items: [
            `Data exchange architecture`,
            `Computing power distribution`,
            `Site layout guidance`,
          ],
        },
        {
          title: `Supply chain management\r\n& Adaptive manufacturing`,
          items: [`Prototyping`, `TIG & MIG Welding`, `FDM & SLA 3D Printing`],
        },
        {
          title: `Industrial Automation\r\n& HMI Interfacing`,
          items: [`Workflow adjustment`, `Malfunction detection`, `Data preview`],
        },
        {
          title: `Software integration\r\n& Data collection`,
          items: [`Administration systems`, `Firmware`, `Open API`],
        },
      ],
      bannerTextsTitle: 'Services',
    },
  },
  CASE_STUDY: `Case study`,
  CASE_STUDIES: `Case Studies`,
  CLIENTS: 'Solutions',
  CLOSE: 'Close',
  COMPANY_NAME: `ambitas.org`,
  CONTACT: `Contact`,
  CONTACT_FORM: {
    BUDGET: 'Budget',
    COMPANY: 'Your company',
    EMAIL: 'Your e-mail',
    INDUSTRY: 'Service',
    MESSAGE: 'Message',
    NAME: 'Your name',
    PHONE: [{ number: '+1 (786) 605-0010' }],
    PRICE_LEVELS: [],
    SEND: `Send message`,
    TITLE: `Quick touch`,
    URL: 'form-sent',
    WHATPREFER: `Prefer email or phone call?`,
  },
  CONTACT_US: `Contact us`,
  COOKIES: `Our website uses cookies which helps to enhance your experience. By using this site you agree with using cookies.`,
  COPYRIGHT: `Copyright © 2009-${new Date().getFullYear()} ambitas. All rights reserved.`,
  COUNTRIES_SERVED: `Countries served`,
  CURRENCY_CHARACTER: '$',
  CUSTOMIZATION: `Customization`,
  DISCOVER_MORE: `Discover More`,
  DISCOVER_RESULTS: `Discover Results`,
  DURATION: 'Duration',
  ERROR_404: `Page was not found`,
  FIND_OUT_MORE: 'Find out more',
  FINISH_SCROLL: `Finish the scrolling`,
  FOOTER_TITLE: `Let's grow together!`,
  FORM_SUCCESS_MESSAGE: `Thank you for getting in touch with us. Our sales team is diligently working on processing your message and will be in contact with you shortly. Meanwhile, feel free to explore our latest blog posts.`,
  GDPR: {
    AGREEMENT: (link: string) =>
      `By clicking the button I agree with the collection and processing of my personal data as described in the <a target="_blank" href="${link}">Privacy policy</a>.`,
    URL: `terms-of-use-and-personal-data-processing`,
    TITLE: `Terms of use, protection and processing of personal data`,
    EMAIL: `gdpr@ambitas.org`,
    ARTICLES: [
      {
        TITLE: `Identification of the operator`,
        TEXTS: [
          `The operator of personal data is ambitas, s. r. o., with its registered office at Ševčenkova 25, 851 01 Bratislava, Slovakia, ID No. 45 280 380, registered in the Business Register of the District Court Bratislava I, Section Sro, File No. 61558 / B (hereinafter referred to as “ambitas”).`,
        ],
      },
      {
        TITLE: `Processing of personal data`,
        TEXTS: [
          `Based on EU Regulation (GDPR), ambitas processes the following personal data of users: name, surname, telephone number and e-mail address, and the contact details or price calculation.`,
          `The specific data that the company collects on the site is linked to website analytics. For this purpose, ambitas imposes on the user's side the so-called cookies that serve additional marketing purposes, evaluate traffic statistics, and user behavior on the company's website.`,
        ],
      },
      {
        TITLE: `Recipients of personal data`,
        TEXTS: [
          `The only recipient of personal data related to the business activity is ambitas.`,
          `Anonymous and non-personally identifiable statistical data related to the actual operation of the site are processed through Google services and the social partners listed in the footer of the website.`,
        ],
      },
      {
        TITLE: `Purpose and legal basis of the processing of personal data`,
        TEXTS: [
          `The purpose of personal data processing is primarily the possibility of the existence of a contact person in the ambitas internal CRM system, regarding potential services, preparing quotations for projects and ensuring further business communication with each other.`,
        ],
      },
      {
        TITLE: `Period of retention of personal data`,
        TEXTS: [
          `The personal data will be stored indefinitely until receiving a request for deletion by the personal data provider (user).`,
          `The user, as the data subject, has the right to request access to personal data, the right to rectify personal data, delete personal data, restrict processing of personal data, object to the processing of personal data and the right to transfer personal data.`,
        ],
      },
      {
        TITLE: `Necessity of personal data processing`,
        TEXTS: [
          `The processing of personal data is an essential procedural requirement as it is necessary for the purpose of contacting the user, for the purpose of business communication.`,
        ],
      },
      {
        TITLE: `Ways to contact ambitas`,
        TEXTS: [
          `Ambitas can be contacted regarding the processing of personal data by means of a written letter delivered to the address of the company: Ševčenkova 25, 851 01 Bratislava, Slovakia or by sending an e-mail to `,
        ],
      },
    ],
    MENU_TITLE: 'Privacy policy',
  },
  GET_TO_KNOW_US: 'Get to know us',
  GET_TO_KNOW_US_BETTER: 'Get to know us better',
  HEROSECTION: {
    ambience: {
      title: 'ambience IoT ecosystem',
      externalUrl: 'https://ambience.ambitas.org',
      seoTitle: 'ambience IoT ecosystem - temperature, humidity, CO2 and much more!',
      text: 'Industrial rugged sensors with extremely long battery life. Combined with our user friendly UI, you can perform realtime data analysis, get notified on highs and lows, export data to various formats and even remotely manage your sensor fleet.',
      tags: [
        { text: 'Engineering', link: 'engineering' },
        { text: 'Custom Software', link: 'custom-software-solutions' },
      ],
      results: [
        {
          duration: 5,
          end: 30,
          suffix: '+',
          title: 'Man-hours\r\nsaved monthly',
        },
        {
          duration: 5,
          suffix: 'Full',
          title: 'Automation\r\nof data acquisition',
        },
        {
          duration: 5,
          end: 14,
          suffix: 'x',
          title: 'Faster resolution time\r\nof incidents',
        },
      ],
    },
    dss: {
      subUrl: 'ambience',
      url: 'environmental-monitoring',
      title: 'Environmental monitoring',
      seoTitle: 'Environmental monitoring - temperature, humidity, CO2 and much more!',
      text: 'Our sensors are used in social care homes for precise sensor readings and critical alerts. Automated anomaly detections help save costs on resource wasting and provides better services by avoiding non-hostile conditions.',
      tags: [
        { text: 'Engineering', link: 'engineering' },
        { text: 'Custom Software', link: 'custom-software-solutions' },
      ],
      results: [
        {
          duration: 5,
          end: 14,
          suffix: 'x',
          title: 'Faster resolution\r\ntime of incidents',
        },
        {
          duration: 5,
          suffix: 'Full',
          title: 'Automation\r\nof data acquisition',
        },
        {
          duration: 5,
          end: 30,
          suffix: '+',
          title: 'Manhours\r\nsaved monthly',
          description:
            'In a medium sized facility with approximately 10 sensors in different locations.',
        },
      ],
    },
    veles: {
      subUrl: 'vertical-farming',
      url: 'vertical-farm',
      title: 'Vertical Farm',
      seoTitle: 'Fully automated vertical farm',
      text: 'This vertical farm enters the doors to the farming of the future by launching automated tasks based on the triggers that are defined by the "digital growing recipe". Various sensors keep an eye on the ambient conditions, which helps with growing premium grade saffron.',
      tags: [
        { text: 'Custom Vertical Farming', link: 'vertical-farming' },
        { text: 'Engineering', link: 'engineering' },
      ],
      results: [
        {
          duration: 5,
          suffix: 'Complete',
          title: 'Remote management',
        },
        {
          duration: 5,
          end: 4,
          suffix: 'x',
          title: 'Production increase',
        },
        {
          duration: 5,
          suffix: '0.1kWh',
          title: 'Daily consumption',
          description:
            'Per square meter with the lights running for 8 hours a day on 50% brightness. Excluding HVAC and water pumps.',
        },
      ],
    },
    veles2: {
      subUrl: 'vertical-farming',
      url: 'vertical-farm-20',
      title: 'Vertical Farm 2.0',
      seoTitle: 'The largest vertical farm for indoor saffron cultivation worldwide',
      text: 'Let us introduce to you our next vertical farming project: <b>Vertical Farm 2.0</b> - <u><b>the World\'s largest vertical farm for growing saffron.</b></u>. An ‘older sibling’ of the previous <a href="/solutions/vertical-farming/case-study/vertical-farm">proof of concept</a>, which comes in a <b>new guise</b> due to the improved build quality and <b>more integrated solutions</b>. This farm has been elevated to another level in several aspects to grow an additional amount of <b>premium grade saffron</b>.',
      tags: [
        { text: 'Custom Vertical Farming', link: 'vertical-farming' },
        { text: 'Engineering', link: 'engineering' },
      ],
      results: [
        {
          duration: 5,
          suffix: '46Wh',
          title: 'Daily consumption',
          description:
            'Per square meter. Considering all lights running on 50% brightness for 12 hours a day. Excluding HVAC and water pumps.',
        },
        {
          duration: 5,
          end: 350,
          suffix: 'm<sup style="margin-right:-16px;">2</sup>',
          title: 'Growing area',
        },
        {
          duration: 5,
          suffix: '4+',
          title: 'Harvests annually',
          description: 'Thanks to the perfectly controlled enviroment and farm automation.',
        },
      ],
    },
    craneAI: {
      title: 'Crane AI',
      seoTitle: 'Crane load monitoring',
      text: 'As a proof of concept, we have created an AI-based device for monitoring the type of load of a crane. Once the type of load gets detected with our machine learning algorithm, the data is automatically uploaded to the cloud to be further analysed and used for planning of the build process.',
      tags: [
        { text: 'Engineering', link: 'engineering' },
        { text: 'Custom Software', link: 'custom-software-solutions' },
      ],
    },
    domsnov: {
      subUrl: 'custom-software-solutions',
      url: 'smart-homes-for-21st-century-iot-domsnov',
      title: 'IoT Dom Snov',
      seoTitle: 'SmartHomes for 21st century DomSnov',
      text: 'Improving comfort of living and transforming the houses into real smart homes for the 21st century. IoT based solution makes it easier for people to get their home environment under control and enjoy more balanced living.',
      tags: [{ text: 'Custom Software', link: 'custom-software-solutions' }],
      results: [
        {
          duration: 3,
          end: 8,
          title: 'Supported IoT devices',
        },
        {
          duration: 2,
          end: 2,
          suffix: ' min',
          prefix: '<',
          title: 'Setup of smart home features',
        },
        {
          duration: 1,
          end: 1,
          title: 'User friendly application',
        },
      ],
    },
    nudch: {
      subUrl: 'custom-software-solutions',
      url: 'minus-70-percent-unnecessary-hospital-visits-nudch-mobile-application',
      title: 'NUDCH',
      seoTitle: 'Telemedicine NUDCH',
      text: 'Mobile application helps to prevent 70% of unnecessary hospital visits for young mothers and their children. Telemedicine principles and smart decision tree algorithms allow for more effective healthcare services.',
      tags: [{ text: 'Custom Software', link: 'custom-software-solutions' }],
      results: [
        {
          duration: 5,
          end: 70,
          prefix: '-',
          suffix: '%',
          title: 'Unnecessary hospital visits',
        },
        {
          duration: 7,
          end: 100,
          suffix: '%',
          title: 'Patient-centric\r\n solution',
        },
        {
          duration: 6,
          end: 75,
          suffix: '%+',
          title: 'Adoption rate of app from patients',
        },
      ],
    },
    smartxcity: {
      video: 'https://www.youtube.com/embed/a4y5B-lQKSw',
      subUrl: 'custom-software-solutions',
      url: 'smartxcity',
      title: 'smartXcity',
      seoTitle: 'Smart cities of the 21st century - smartXcity',
      text: 'The smartXcity application is a pilot project and a proof of concept for smart city technologies. This application can be considered as a proof of the IoT beacons functionality and smartcity solutions possibilities for real world applications.',
      tags: [{ text: 'Custom Software', link: 'custom-software-solutions' }],
      results: [
        {
          duration: 5,
          end: 100,
          suffix: '%',
          title: 'Accessibility for the disabled',
        },
        {
          duration: 5,
          end: 100,
          suffix: '%',
          title: 'Citizens awareness of the city',
        },
      ],
    },
    telekom: {
      subUrl: 'custom-software-solutions',
      url: 'mobile-application-telekom-night-run',
      title: 'Telekom',
      seoTitle: 'Mobile application Telekom NightRun',
      text: 'Supportive event application for the running event in Slovakia sponsored by Slovak Telekom. Mobile application was valuable for more than 5k users that found essential information regarding the event.',
      tags: [{ text: 'Custom Software', link: 'custom-software-solutions' }],
      results: [
        {
          duration: 2,
          end: 5,
          suffix: 'k+',
          title: 'Active users',
        },
        {
          duration: 5,
          end: 100,
          suffix: '%',
          title: 'Brand recognition',
        },
      ],
    },
    oktagon: {
      subUrl: 'custom-software-solutions',
      url: 'backbone-administrative-system-oktagon',
      title: 'OKTAGON',
      seoTitle: 'Backbone administrative system OKTAGON',
      text: 'Backbone administration system for simple and intuitive content management for their mobile application. The system provides the right tools for communication, administration and management.',
      tags: [{ text: 'Custom Software', link: 'custom-software-solutions' }],
      results: [
        {
          duration: 5,
          end: 100,
          suffix: '%',
          title: 'Content management',
        },
        {
          duration: 4,
          end: 25,
          suffix: 'k+',
          title: 'Mobile app users',
        },
      ],
    },
    samsung: {
      subUrl: 'custom-software-solutions',
      url: 'promo-mobile-application-samsung',
      title: 'Samsung',
      seoTitle: 'Promotion mobile application Samsung',
      text: 'Mobile application for promotion of launch of the new Samsung cell phones A8 and S9+. A8 key feature is high-quality camera with face detection and S9+ prides its impressive slow-motion video capture.',
      tags: [{ text: 'Custom Software', link: 'custom-software-solutions' }],
      results: [
        {
          duration: 5,
          end: 100,
          suffix: '%',
          title: 'Brand recognition',
        },
      ],
    },
    slovaktual: {
      subUrl: 'custom-software-solutions',
      url: 'logistic-and-asset-management-application-slovaktual',
      seoTitle: 'Logistic & Asset Management App Slovaktual',
      title: 'Logistic app',
      subtitle: 'Part of Arbonia Group',
      text: "Clients, Drivers, Warehouse workers and Supply chain managers all connected through one logistic application used for tracking of deliveries from the warehouse all the way to the clients' location and back.",
      tags: [{ text: 'Custom Software', link: 'custom-software-solutions' }],
      results: [
        {
          duration: 5,
          end: 96,
          suffix: '%+',
          title: 'Racks’ return rate',
        },
        {
          duration: 5,
          end: 100,
          suffix: '%',
          title: 'Location overview',
        },
        {
          duration: 5,
          // end: 1000,
          suffix: 'Thousands',
          title: 'In cost savings',
        },
      ],
    },
    webswing: {
      subUrl: 'custom-software-solutions',
      url: 'custom-software-information-system-crm-webswing',
      seoTitle: 'Webswing CRM',
      title: 'Webswing',
      text: 'Building the brand identity through custom software solution while providing the powerful tool to manage company information workflow in all of its complexity. That is essentially what we’ve created and are maintaining to this day.',
      tags: [{ text: 'Custom Software', link: 'custom-software-solutions' }],
      results: [
        {
          duration: 5,
          end: 5,
          title: 'Individual software solutions',
        },
        {
          duration: 1,
          end: 1,
          title: 'Homogeneous system',
        },
        {
          duration: 5,
          end: 100,
          suffix: '%',
          title: 'Branded experience',
        },
      ],
    },
    terminyorg: {
      subUrl: 'custom-software-solutions',
      url: 'web-app-available-covid-vaccines-apointments',
      seoTitle: 'Web app terminy.org',
      title: 'Terminy.org',
      text: 'Web application that alleviated the chaos in vaccination in Slovakia at the beginning of 2021. We provided information about available appointments for vaccination in a very clear and transparent manner.',
      tags: [{ text: 'Custom Software', link: 'custom-software-solutions' }],
      results: [
        {
          duration: 5,
          end: 5,
          title: 'Weeks of project duration',
        },
        {
          duration: 2,
          end: 1.5,
          suffix: 'm+',
          title: 'Website visits',
        },
      ],
    },
    redbull: {
      subUrl: 'custom-software-solutions',
      url: 'redbull',
      title: 'Redbull',
      text: 'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.',
      tags: [{ text: 'Custom Software', link: 'custom-software-solutions' }],
      results: [
        {
          duration: 5,
          end: 150,
          suffix: '+',
          title: 'Customer satisfaction',
        },
        {
          duration: 5,
          end: 15,
          suffix: '+',
          title: 'Data retention',
        },
        {
          duration: 5,
          end: 1000,
          suffix: '+',
          title: 'Positive feedback',
        },
      ],
    },
    tasr: {
      subUrl: 'custom-software-solutions',
      url: 'tasr-webreport-online-media-search-tool',
      title: 'TASR',
      seoTitle: 'TASR Webreport online media search tool',
      text: 'How to make searching the web faster and more approachable? Everyday practice has established a need for a tool that provides fast online media searching. Instant and automatic reporting without the need for daily search through hundreds of web portals.',
      tags: [{ text: 'Custom Software', link: 'custom-software-solutions' }],
      results: [
        {
          duration: 8,
          end: 1000,
          suffix: '+',
          title: 'Monitored webs',
        },
        {
          duration: 6,
          end: 10,
          suffix: '+',
          title: 'Government clients',
        },
        {
          duration: 9,
          end: 50,
          suffix: 'm+',
          title: 'Search Results',
        },
      ],
    },
    ravalTrade: {
      subUrl: 'custom-software-solutions',
      url: 'b2c-b2b-raval-trade-ecommerce',
      title: 'Raval Trade',
      seoTitle: 'B2C B2B Raval Trade',
      text: 'Powerful business intelligence platform created for managing different B2C ecommerce end-points and for supporting the entire B2B sales channel of the company. Everything managed from a single point of administration.',
      tags: [{ text: 'Custom Software', link: 'custom-software-solutions' }],
      results: [
        {
          duration: 5,
          end: 10,
          suffix: 'k+',
          title: 'B2B and B2C customers',
        },
        {
          duration: 3,
          end: 5,
          suffix: '+',
          title: '3rd party\r\n integrations',
        },
        {
          duration: 1,
          end: 1,
          title: 'Management\r\n Platform',
        },
      ],
    },
    bauholding: {
      subUrl: 'custom-software-solutions',
      url: 'custom-information-system-bauholding',
      title: 'Bauholding',
      seoTitle: 'Custom Information System Bauholding',
      text: `Massive administration system supporting every single business process in the company. Improved planning efficiency and strong focus on data workflow while building hundreds of new houses is crucial for the company's performance.`,
      tags: [{ text: 'Custom Software', link: 'custom-software-solutions' }],
      results: [
        {
          duration: 4,
          end: 60,
          suffix: '+',
          title: 'Enterprise modules',
        },
        {
          duration: 3,
          end: 14,
          title: '3rd party integrations',
        },
        {
          duration: 5,
          end: 73,
          prefix: '+',
          suffix: '%',
          title: 'Planning efficiency',
        },
      ],
    },
    arisan: {
      subUrl: 'custom-software-solutions',
      url: 'accounting-reporting-software-web-solution-arisan',
      title: 'Arisan',
      seoTitle: 'Accounting Software Arisan',
      text: 'Accounting software previously only available as a desktop application, migrated to the web in order to seamlessly provide accounting data and reports closer to the clients. Now accessible on any device or platform.',
      tags: [{ text: 'Custom Software', link: 'custom-software-solutions' }],
      results: [
        {
          duration: 5,
          end: 400,
          suffix: '+',
          title: 'Active clients',
        },
        {
          duration: 5,
          end: 23,
          prefix: '+',
          suffix: '%',
          title: 'Workflow efficiency',
        },
        {
          duration: 5,
          end: 96,
          suffix: '%',
          title: 'Users Satisfaction',
        },
      ],
    },
    csob: {
      subUrl: 'custom-software-solutions',
      url: 'mobile-application-csob-bratislava-marathon',
      title: 'CSOB Marathon',
      seoTitle: 'Mobile application CSOB Bratislava Marathon',
      text: 'Supportive event application for one of the biggest running events in Slovakia. All information about the event was valuable for more than 10k users which kicked the app up to first position in downloads on AppStore.',
      tags: [{ text: 'Custom Software', link: 'custom-software-solutions' }],
      results: [
        {
          duration: 3,
          end: 10,
          suffix: 'k+',
          title: 'Active\r\nusers',
        },
        {
          duration: 1,
          end: 1,
          suffix: 'st',
          title: 'On AppStore in sports category',
        },
        {
          duration: 5,
          end: 100,
          suffix: '%',
          title: 'Brand\r\nrecognition',
        },
      ],
    },
    o2: {
      subUrl: 'custom-software-solutions',
      url: 'supportive-event-application-o2',
      title: 'O2',
      seoTitle: 'O2 sports day application',
      text: 'Supportive event application helped to enjoy the company’s sports day for more than 400 employees. Tracking the results on various bases with barcodes and clear presentation of results on a shared screen for everybody.',
      tags: [{ text: 'Custom Software', link: 'custom-software-solutions' }],
      results: [
        {
          duration: 6,
          end: 400,
          suffix: '+',
          title: 'Active\r\nusers',
        },
        {
          duration: 3,
          end: 3,
          title: 'Years of consecutive usage',
          suffix: '+',
        },
      ],
    },
    ibd: {
      subUrl: 'custom-software-solutions',
      url: '25-percent-time-savings-on-doctors-examinations-ibd-mobile-application',
      title: 'IBD',
      seoTitle: 'Patient-centric IBD mobile app',
      text: 'Patient-centric mobile application aimed to provide better healthcare service, improve and intensify communication between patient and doctor and shorten the time required for face-to-face examinations and regular check-ups.',
      tags: [{ text: 'Custom Software', link: 'custom-software-solutions' }],
      results: [
        {
          duration: 3,
          end: 25,
          prefix: '-',
          suffix: '%',
          title: 'Duration of examination',
        },
        {
          duration: 5,
          end: 90,
          suffix: '%+',
          title: 'Adoption rate of app from patients',
        },
        {
          duration: 6,
          end: 100,
          suffix: '%',
          title: 'Patient-centric\r\nsolution',
        },
      ],
    },
    ppec: {
      subUrl: 'custom-software-solutions',
      url: 'ppe-center-us-marketplace',
      title: 'PPE Center',
      seoTitle: 'PPECenter Marketplace',
      text: 'Business networking platform and marketplace aimed to provide verification services, fighting the fraud and securing the smooth business process for business owners while trading with personal protective equipment.',
      tags: [{ text: 'Custom Software', link: 'custom-software-solutions' }],
      results: [
        {
          duration: 2,
          end: 2,
          suffix: 'k+',
          title: 'Professional traders',
        },
        {
          duration: 1,
          end: 1,
          prefix: '$',
          suffix: 'B+',
          title: 'Value of traded goods',
        },
        {
          duration: 1,
          end: 1,
          title: 'Verification authority',
        },
      ],
    },
    merchyou: {
      subUrl: 'custom-software-solutions',
      url: 'ecommerce-platform-merchyou',
      title: 'MERCHYOU',
      seoTitle: 'E-commerce platform MerchYOU',
      text: 'Multi-shared e-commerce platform created for selling of merchandise to individual clients. Thanks to this application, clients can create their own e-shop to sell their products, with their own branding and design. The total creation takes less than 2 hours and the API connection directly with the merch manufacturer will ensure seamless delivery directly to customers.',
      tags: [{ text: 'Custom Software', link: 'custom-software-solutions' }],
      results: [
        {
          duration: 5,
          end: 100,
          suffix: '%',
          title: 'Brand recognition',
        },
      ],
    },
    substitutes: {
      subUrl: 'custom-software-solutions',
      url: '100-percent-of-vacciness-utilized-information-system-nahradnici',
      title: 'Nahradnici.sk',
      seoTitle: 'Information system Nahradnici.sk',
      text: 'Information system that aimed to provide the support for state vaccination strategy during the COVID-19 pandemic. Main objective for this substitutes management system was to reach 100% usage of available vaccines.',
      tags: [{ text: 'Custom Software', link: 'custom-software-solutions' }],
      results: [
        {
          duration: 6,
          end: 30,
          suffix: 'k+',
          title: 'Registered accounts',
        },
        {
          duration: 4,
          end: 15,
          suffix: 'k+',
          title: 'Vaccinated citizens',
        },
        {
          duration: 1,
          prefix: '<0.1%',
          title: 'Invitation failure rate',
        },
      ],
    },
  },
  HOME: 'Home',
  HOW_WE_DO_IT: 'How we do it?',
  IN_MEDIA: `ambitas in media`,
  INOVATIVNESS_TITLE: `Vertical Farming Adventures`,
  INOVATIVNESS_TEXT: `We also specialize in engineering revolutionary <b>vertical farms</b>, which represent a unique blend of our <b>expertise</b> in <b>hardware</b>, <b>IoT</b>, and <b>software</b>. We provide a full service package, from design and building up to <b>fully operational vertical farms</b>.`,
  INTERESTED_IN_MORE: 'Interested in getting similar results for your company?',
  KEY_PEOPLE_TITLE: 'Key people',
  KEY_PEOPLE_SUBTITLE: 'Some of the people who make it possible to keep our show running forward.',
  KEY_PEOPLE: {
    mBalaz: {
      title: 'Martin Balaz',
      subtitle: 'CEO & Founder',
    },
    eMichalik: {
      title: 'Eduard Michalik',
      subtitle: 'CTO, Software Architect',
    },
    rSlamka: {
      title: 'Robert Slamka',
      subtitle: 'Lead Programmer, R&D',
    },
    mPalicka: {
      title: 'Martin Palicka',
      subtitle: 'Head Graphic Designer',
    },
    rNaroda: {
      title: 'Rastislav Naroda',
      subtitle: 'FrontEnd Architect',
    },
    mkKolibjarova: {
      title: 'Maria Klara Kolibjar',
      subtitle: 'Marketing Specialist',
    },
    lSchmoger: {
      title: 'Lenka Schmoger',
      subtitle: 'Account Management',
    },
    rKovac: {
      title: 'Richard Kovac',
      subtitle: 'Hardware Engineer, R&D',
    },
    eNegruta: {
      title: 'Eduard Negruta',
      subtitle: 'FrontEnd Specialist',
    },
  },
  LATEST_POSTS: 'Latest posts...',
  LOADING: 'Loading...',
  LINK_VIDEO: 'https://www.youtube.com/embed/kwHcNzeKieI',
  MAJOR_BENEFITS: `Major benefits`,
  MEDIA: {
    startitup1: 'Finalists of CESAwards Slovakia 2019',
    biznisklub: 'The best startup stars in Slovakia',
    forbes: 'Best Software Development Partner - ambitas',
    startitup2: 'Winners of CESAwards Slovakia 2019',
    startitupCovid: 'Young Slovaks facilitated COVID vaccination',
    avf: 'Becoming a member of AVF',
  },
  MORE_ABOUT_US: `More about us`,
  MORE_INSIGHTS: `More insights`,
  VIDEO: 'Video',
  NAVBAR_TITLE_ICON: 'Get in touch',
  NEXT: `Next`,
  OTHER: 'Other',
  OTHER_SOLUTIONS: 'Other\r\nSolutions',
  OUR: 'Our',
  OUR_DESCRIPTION: [
    `Our tailored <b>engineering solutions</b>, cutting-edge <b>hardware</b> and <b>software</b> help you <b>measure</b>, <b>collect</b>, and <b>analyze</b> your data, make it accessible for you, useful for your business, and serve as the foundation for <b>fully automated operations</b>.`,
  ],
  OUR_PROCESS: 'Our process',
  OUR_SOLUTIONS: 'Our solutions',
  OUR_VALUES: 'Our values',
  OUR_LATEST_BLOGS: 'Our latest blogs',
  PAGE_TITLE: {
    ABOUT_US: 'About Us',
    HOME: 'Engineering, Vertical Farming & IoT',
    SOLUTIONS: 'IoT & Engineering Solutions',
    LOGO: 'Logo Assets',
  },
  PRIVACY_POLICY: `Privacy policy`,
  QUICK_TOUCH: `Quick touch`,
  READ_MORE: `READ MORE`,
  REFERENCES: 'Testimonials',
  REPLAY: 'Replay',
  RESULTS: 'Results',
  RIGHT_SOFTWARE_SOLUTIONS: 'Innovative software solutions\r\n for your business.',
  RIGHT_SOFTWARE_SOLUTIONS_DESCRIPTION: 'Software solutions that save costs and help you grow.',
  ROUTES: {
    home: '',
    aboutUs: 'about-us',
    blog: 'blog',
    solutions: 'solutions',
    caseStudy: 'case-study',
    logo: 'logo',
  },
  SAID_ABOUT_US: 'Said about us',
  SCROLL_DOWN: `Scroll down`,
  SEE_ALL: `All solutions`,
  SEE_DETAILS: `see details of our solutions`,
  SEE_MORE_BLOG_POSTS: 'see more blog posts',
  SEO_DESCRIPTIONS: {
    aboutUs: `Since 2009, we specialise in the development of high-quality digital solutions for companies in different industry segments. Hardware, software and engineering solutions that help you grow.`,
    blog: `Get to know more about what we do, how we do it and how can you benefit from it!`,
    home: `Experience the power of tailored engineering & IoT Solutions! Developing custom hardware, software, engineering & IoT solutions from concept to completion since 2009.`,
    solutions: `Tailored software, hardware and engineering solutions developed for individual needs of clients to achieve competitive advantage at reasonable cost.`,
    CASES: {
      ambience: `Innovative data-based solutions for agriculture, livestock management and manufacturing.`,
      customSoftwareSolutions: `Customized software solutions developed for individual clients' needs to provide competition advantage in a cost-effective way.`,
      engineering: `Customized hardware solutions developed for individual client's needs to provide competition advantage in a cost-effective way.`,
      verticalFarming: `Engineering vertical farms that blend horticulture with agricultural technology for fast, year-round, sustainable production in controlled environments.`,
    },
    logo: `Download various formats of our logo.`,
  },
  SEO_KEYWORDS: {
    aboutUs: [
      'web apps',
      'information systems',
      'software',
      'web design',
      'mobile apps',
      'cms',
      'websites',
      'web pages',
      'web',
      'e-shop',
      'online shop',
      'SEO',
      'online marketing',
      'marketing',
      'ppc',
      'pay per click',
      'ppc ads',
      'custom',
      'business',
      'coder',
      'crm',
      'content management',
      'propagation',
      'modern web',
      'developer',
      'developers',
      'engineering',
      'internet of things',
      'ecosystem',
      'measurements',
      'smart',
      'iot',
      'digital measurement',
      'tracking',
      'soldering',
      'PCB',
      'prototyping',
    ],
    home: [
      'web apps',
      'information systems',
      'software',
      'web design',
      'mobile apps',
      'cms',
      'websites',
      'web pages',
      'web',
      'e-shop',
      'online shop',
      'SEO',
      'online marketing',
      'marketing',
      'ppc',
      'pay per click',
      'ppc ads',
      'custom',
      'business',
      'coder',
      'crm',
      'content management',
      'propagation',
      'modern web',
      'developer',
      'developers',
      'engineering',
      'internet of things',
      'ecosystem',
      'measurements',
      'smart',
      'iot',
      'digital measurement',
      'tracking',
      'soldering',
      'PCB',
      'prototyping',
    ],
    solutions: [
      'web apps',
      'information systems',
      'software',
      'web design',
      'mobile apps',
      'cms',
      'websites',
      'web pages',
      'web',
      'e-shop',
      'online shop',
      'SEO',
      'online marketing',
      'marketing',
      'ppc',
      'pay per click',
      'ppc ads',
      'custom',
      'business',
      'coder',
      'crm',
      'content management',
      'propagation',
      'modern web',
      'developer',
      'developers',
      'engineering',
      'internet of things',
      'ecosystem',
      'measurements',
      'smart',
      'iot',
      'digital measurement',
      'tracking',
      'soldering',
      'PCB',
      'prototyping',
    ],
    video: [
      'web apps',
      'information systems',
      'software',
      'web design',
      'mobile apps',
      'cms',
      'websites',
      'web pages',
      'web',
      'e-shop',
      'online shop',
      'SEO',
      'online marketing',
      'marketing',
      'ppc',
      'pay per click',
      'ppc ads',
      'custom',
      'business',
      'coder',
      'crm',
      'content management',
      'propagation',
      'modern web',
      'developer',
      'developers',
    ],

    CASES: {
      ambience: [],
      customSoftwareSolutions: [],
      engineering: [],
    },
  },
  SEO_SITENAMES: {
    aboutUs: 'About Us - ambitas.org',
    home: 'IoT & Engineering Solutions - ambitas.org',
    solutions: 'IoT & Engineering Solutions - ambitas.org ',

    CASES: {
      customSoftwareSolutions: 'Custom Software Solutions',
      ambience: 'ambience IoT Solutions',
      engineering: 'Complex Engineering Solutions',
      verticalFarming: 'Custom Vertical Farming',
    },
  },
  SOLUTIONS: `Solutions`,
  SOLUTIONS_CLICK_TO_SELECT: `click on any to select the item`,
  SOLUTIONS_SUBTITLE: `We specialise in developing and engineering of robust software & hardware solutions that save resources and help you grow.`,
  TAKE_ME_HOME: 'Take me home',
  TECHNICAL_DETAILS: 'Technical details',
  TEAM: 'Team',
  TEAM_DESCRIPTION: `We recognize the unique needs of each project and provide <b>tailor-made engineering solutions</b> and <b>IoT solutions</b>. Our dedicated team works closely with clients from <b>various industries to develop customized approaches</b> that meet specific requirements, ensuring that every solution is perfectly <b>aligned with their goals</b>.`,
  TESTIMONIAL_TITLE: 'Testimonial',
  TESTIMONIALS_TITLE: `Testimonials`,
  TESTIMONIALS: {
    DIAMAT: {
      title: `Albert Balasz`,
      subtitle: `CEO, Diamat`,
      text: `Doing the business successfully is not just about working hard, having the right people around you and being in the right place, but also working with business partners, who can propel you forward with their services and products. We see ambitas as the right partner for this job.`,
    },
    SLOVAKTUAL: {
      title: `Meciar Miroslav`,
      subtitle: `HEAD of IT, Slovaktual`,
      text: `The entire ambitas team has met our expectations and with their professional and open approach, we continue to develop our application. Particularly speaking, they have supplied us with their technical expertise and multilingualism, both essential to our project.`,
    },
    MERCHYOU: {
      title: `Sisovic Drahos`,
      subtitle: `CEO, MERCHYOU`,
      text: `We highly recommend cooperation with ambitas. Professional, fast and pleasant attitude is a matter of course at ambitas and that's one of the main reasons why we are looking forward to our further cooperation.`,
    },
    ILCC: {
      title: `Bichler Klaus`,
      subtitle: `Managing Director, ILCC-Group`,
      text: `I have worked on a great future-oriented project in cooperation with ambitas. They have fulfilled all of my requirements and provided great support we needed. I highly recommend working with them.`,
    },
    WEBSWING: {
      title: `Meszaros Viktor`,
      subtitle: `Webswing Developer & Founder, Webswing`,
      text: `Webswing has already done couple of quite complicated web application projects with the ambitas team. We’ve always relied on their ingenuity of solutions that help our customers get the software they need, on time and in the proper quality.`,
    },
    NUDCH: {
      title: `Kužela Ladislav`,
      subtitle: `Director, NUDCH, Jan 2020`,
      text: `The hard work of the entire ambitas team has brought tangible and measurable results to our hospital. However the most important fact is that this well pulled off project and technological advancement is beneficial for our patients and their families.`,
    },
    ARISAN: {
      title: 'Dudas Jan',
      subtitle: 'Founder, ARISAN',
      text: 'The project was delivered on time and all of the users are pleased with the results. Regular communication and quick response from ambitas is one of the main aspects, that makes progress.',
    },
    VELES: {
      title: 'Miroslav Hroncek',
      subtitle: 'CEO, Veles Farming',
      text: 'Our experience with ambitas has been outstanding. Their innovative ideas, customized solutions and exceptional communication led to our success. I highly recommend them for their dedication to excellence and genuine client focus.',
    },
    DSS: {
      title: 'Maria Kerenyi',
      subtitle: 'Head of the health department, Integra - BSK',
      text: 'In our facility, we need to regularly measure temperature, light and humidity to prevent wasting resources and to fulfill our legal obligations. The ambience solution helped us to fully automate and refine these activities, giving us more time to focus on our clients.',
    },
    YIT: {
      title: 'Boris Kajos',
      subtitle: 'Lean Coordinator, YIT Slovakia',
      text: 'We have collaborated with ambitas on creating the proof-of-concept AI solution in construction industry. Reaction times to our ever-changing requirements were remarkable and their forward-thinking approach helped us to get to the much needed data in a very short period of time.',
    },
  },
  SUBTITLE_HOME: `From concept to completion, full-scale\r\ncustom engineering & IoT`,
  TITLE_HOME: `Experience the power of tailored engineering & IoT solutions!`,
  TRUSTED_BY_BRANDS: 'Trusted by...',
  UNITS: {
    MINUTES: `Minutes`,
    HOURS: `Hours`,
    DAYS: `Days`,
    WEEKS: `Weeks`,
    MONTHS: `Months`,
    THOUSANDS: 'Thousands',
  },
  USE_CASES: `Use Cases`,
  VERTICAL_FARMING_SERVICES: {
    design: {
      subtitle: 'Crafting space-efficient designs for modern agriculture',
      text: 'We specialize in designing and planning efficient vertical farms, adaptable to both existing buildings and new constructions. Our focus is on optimizing essential utilities such as water, lighting, and climate control, ensuring high-efficiency operations in any space. We precisely tailor our designs to meet specific production goals, incorporating the latest in vertical farming technology for a seamless and sustainable urban farming experience.',
      title: 'Design & Planning',
      formTitle: 'Design & Planning',
      shortText:
        'We specialize in designing and planning efficient vertical farms, adaptable to both existing buildings and new constructions. Our focus is on optimizing essential utilities such as water, lighting, and climate control, ensuring high-efficiency operations in any space. We precisely tailor our designs to meet specific production goals, incorporating the latest in vertical farming technology for a seamless and sustainable urban farming experience.',
    },
    building: {
      subtitle: 'Innovative solid foundation setups with precision and efficiency',
      text: 'Our approach in the field of building and installation includes the development of custom racking systems and trays, adjusted for space efficiency and adaptability, alongside advanced hardware and custom electronics specifically designed for urban agriculture environments. We provide complete on-site installation, ensuring that every element of the vertical farm, from the innovative racking solutions to the precision electronics, is set up for optimal performance and ease of operation.',
      title: 'Building & Installation',
      formTitle: 'Building & Installation',
      shortText:
        'Our approach in the field of building and installation includes the development of custom racking systems and trays, adjusted for space efficiency and adaptability, alongside advanced hardware and custom electronics specifically designed for urban agriculture environments. We provide complete on-site installation, ensuring that every element of the vertical farm, from the innovative racking solutions to the precision electronics, is set up for optimal performance and ease of operation.',
    },
    environmentControl: {
      subtitle: 'Optimizing growth conditions with advanced, controlled environmental systems',
      text: 'Incorporation of advanced environment control devices is an essential part of vertical farming technology. By integrating HVAC and dehumidifier systems, we maintain the ideal growing conditions complemented by precision lighting solutions that meet the specific needs of each plant. Our systems feature advanced irrigation controls, efficient water circulation mechanisms, custom IoT sensors, comprehensive software & data analytics. These adjustments ensure optimization of every aspect of the indoor farming ecosystem for maximum yield and efficiency.',
      title: 'Environment control',
      formTitle: 'Environment control',
      shortText:
        'Incorporation of advanced environment control devices is an essential part of vertical farming technology. By integrating HVAC and dehumidifier systems, we maintain the ideal growing conditions complemented by precision lighting solutions that meet the specific needs of each plant. Our systems feature advanced irrigation controls, efficient water circulation mechanisms, custom IoT sensors, comprehensive software & data analytics. These adjustments ensure optimization of every aspect of the indoor farming ecosystem for maximum yield and efficiency.',
    },
    monitoring: {
      subtitle: 'Empowering progress with automation',
      text: 'Our vertical farming solutions are at the forefront of monitoring and automation, ensuring efficiency and optimal growth. We specialize in selecting, designing, and manufacturing custom IoT sensors for an array of parameters including CO2 levels, temperature, humidity, pH, and plant leaf surface temperature. These sensors can be installed throughout the entire farm or adapted for individual trays, providing precise, real-time data. Our advanced cloud-based farm management system integrates these inputs, enabling full automation and control through a web or mobile app.',
      title: 'Monitoring & Farm automation',
      formTitle: 'Monitoring & Farm automation',
      shortText:
        'Our vertical farming solutions are at the forefront of monitoring and automation, ensuring efficiency and optimal growth. We specialize in selecting, designing, and manufacturing custom IoT sensors for an array of parameters including CO2 levels, temperature, humidity, pH, and plant leaf surface temperature. These sensors can be installed throughout the entire farm or adapted for individual trays, providing precise, real-time data. Our advanced cloud-based farm management system integrates these inputs, enabling full automation and control through a web or mobile app.',
    },
    innovations: {
      subtitle: 'Maximizing potential, minimizing effort',
      text: `We're advancing the field with further automation and technological innovation, including water filtration and reuse systems, highlighting our commitment to sustainability. Our in-house engineering solutions enable us to manufacture everything required for a vertical farm, focusing on high efficiency in both energy and water consumption. We integrate sustainable practices like solar panels on rooftops and seamlessly incorporating HVAC systems into our comprehensive information system. This holistic approach aligns with our vision of advanced agricultural solutions.`,
      title: 'Optimizations & Innovations',
      formTitle: 'Optimizations & Innovations',
      shortText: `We're advancing the field with further automation and technological innovation, including water filtration and reuse systems, highlighting our commitment to sustainability. Our in-house engineering solutions enable us to manufacture everything required for a vertical farm, focusing on high efficiency in both energy and water consumption. We integrate sustainable practices like solar panels on rooftops and seamlessly incorporating HVAC systems into our comprehensive information system. This holistic approach aligns with our vision of advanced agricultural solutions.`,
    },
    support: {
      subtitle: 'Commitment beyond manufacturing & installation',
      text: 'At ambitas, we are dedicated to providing continuous support for our vertical farming systems, ensuring that our products consistently perform at their best. We offer complete technical assistance, thorough training and regular maintenance, empowering clients with the knowledge and skills to optimally manage their vertical farms. Additionally, our post-installation services are designed to offer ongoing support, ensuring that each vertical farm we install continues to thrive and evolve with the latest advancements in the field.',
      title: 'Continuous support',
      formTitle: 'Continuous support',
      shortText:
        'At ambitas, we are dedicated to providing continuous support for our vertical farming systems, ensuring that our products consistently perform at their best. We offer complete technical assistance, thorough training and regular maintenance, empowering clients with the knowledge and skills to optimally manage their vertical farms. Additionally, our post-installation services are designed to offer ongoing support, ensuring that each vertical farm we install continues to thrive and evolve with the latest advancements in the field.',
    },
  },
  WATCH_ON: 'also watch on',
  WATCH_THE_VIDEO: 'Watch the video',
  WHAT_WE_DO_TITLE: `What we do?`,
  WHAT_WE_DO_TEXT: `Benefiting from a rich history in <b>software development</b> and years of <b>research and development</b> in <b>hardware</b> and <b>electronics</b>, we provide in-depth and exceptional <b>proficiency</b> in building integrated <b>IoT solutions</b> that help you leverage your data, as well as constructing <b>entirely autonomous units</b>.`,
  WHO_IS_IT_FOR: 'Who is it for?',
  WHO_IS_RESPONSIBLE: `Who is responsible?`,
  WHO_WE_ARE: `Who we are\xA0?`,
  WHY_SHOULD_YOU_DO_IT: 'Why should you do it?',
  WORKFLOW_STEPS: [
    {
      title: 'Project specification',
      text: 'In the very early stages of the project, we focus on the specification of software, hardware and product as a whole. This step often involves regular meetings and communication between us and the client.',
    },
    {
      title: 'Research & design',
      text: 'As all of the products are usually very unique and solve completely new problems with the use of latest technologies, our team focuses on high quality research and architecture, construction, UI and electronics design of the product to ensure a smooth development.',
    },
    {
      title: 'Software development',
      text: 'After finishing all the specifications, research and designs, the software team jumps right into work and starts the development of the software pieces, whether it is firmware, API or even an entire information system.',
    },
    {
      title: 'Hardware engineering',
      text: 'To speed up the process, our hardware engineers are working in parallel with software engineers. Soldering components onto the PCBs, wiring-up all the sensors and working on construction.',
    },
    {
      title: 'Product delivery',
      text: 'As the last piece of the puzzle, everything gets delivered to the client and assembled. All of the electronics are wired up, connected and tested. Based on the character of the individual project, this may take several days. After successful assembly, implementation verification, testing and handover, the product is officially done!',
    },
    {
      title: 'Maintenance & SLA',
      text: "We've got you covered even after the product is finished! For quick support, updates and easier upgrades, you can request a SLA tailored just for your needs.",
    },
  ],
  YEARS_OF_EXPERIENCE: `Years of Experience`,
}

export default i18n
